import { firebaseDatabase } from './config';

const surveyRef = firebaseDatabase.ref('survey');
const answersRef = firebaseDatabase.ref('answers');
const linkOpenCountRef = firebaseDatabase.ref('linkOpenCount');

export const getSurvey = async serveyName => {
  const linkCount = await linkOpenCountRef.child(serveyName).once('value').then(snapshot => snapshot.val());
  await linkOpenCountRef.child(serveyName).set({ count: linkCount && linkCount.count ? linkCount.count + 1 : 1 });
  return surveyRef.child(serveyName).once('value').then(snapshot => snapshot.val())
};

export const pushAnswer = data =>{
  try {
    answersRef.push(data);
  } catch (error) {
    console.log(error);
  }
};
