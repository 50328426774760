import React, { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { getSurvey, pushAnswer } from '../../database/repository';
import InputMask from "react-input-mask";

import Logo from '../../components/Logo';

import './styles.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

export default ({
  match: {
    params: {
      surveyId
    }
  }
}) => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [telephone, setTelephone] = useState('');
  const [email, setEmail] = useState('');
  const [scoreNps, setScoreNps] = useState(false);
  const [npsJustification, setNpsJustification] = useState('');
  const [surveyData, setSurveyData] = useState({});
  const [ratings, setRatings] = useState({});
  const [maillErrorMessage, setMaillErrorMessage] = useState(false);

  useEffect(() => {
    async function fetchSurvey() {
      return await getSurvey(surveyId)
    }
    fetchSurvey().then(survey => {
      setSurveyData(survey);
    });
    setTimeout(() => {
      setStep(1);
    }, 1000)
  }, [surveyId]);

  function isValidMail(email) {
    const mailRegex = /^[A-Za-z0-9._%+-]+@([a-z0-9-]+.)[a-z0-9]+.[a-z]{2,3}$/;
    return !!email.trim().toString().match(mailRegex);
  }

  function checkValidValuesInObject(object) {
    if (!object.email.length) {
      delete object.email;
    }
    if (!object.name.length) {
      delete object.name;
    }
    if (!object.telephone.length) {
      delete object.telephone;
    }
    if (!object.npsJustification.length) {
      delete object.npsJustification;
    }
    if (Object.keys(object.ratings).length === 0) {
      delete object.ratings
    }
    return object
  }

  function handleCheckEmail(event) {
    event.preventDefault();
    if (email.length && !isValidMail(email)) {
      setMaillErrorMessage(true);
      return;
    }
    setMaillErrorMessage(false);
    setStep(2);
  }

  const handleAnswer = async event => {
    event.preventDefault();
    let data = {
      placeId: surveyData.placeId,
      surveyId,
      name,
      telephone,
      email,
      scoreNps,
      npsJustification,
      ratings
    }
    data = checkValidValuesInObject(data);
    await pushAnswer(data);
    setStep(3);
  }

  const handleRating = (item, value) => {
    const newRatings = {
      ...ratings,
      [item]: value
    }
    setRatings(newRatings);
  }

  const widgets = {
    firstSteep: _ => (
      <>
        <h5>Antes de começar, seria legal contarmos com algumas dados de contato. Eles não são obrigatórios.</h5>
        <label htmlFor='name'>Qual é o seu nome ou apelido?</label>
        <input
          type='text'
          className='form-control'
          id='name'
          placeholder='Nome:'
          value={name}
          onChange={(event) => setName(event.target.value)}
          maxLength="100"
        />
        <br />
        <label>Precisamos também de alguma forma de contato:</label>
        <InputMask
          mask="(99) 99999-9999"
          className='form-control'
          id='telephone'
          placeholder='Telefone celular:'
          value={telephone}
          onChange={(event) => setTelephone(event.target.value)}
        />
        <div style={{ margin: '5px' }}>e/ou</div>
        <input
          type='email'
          className='form-control'
          id='email'
          placeholder='E-mail:'
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          maxLength="100"
        />
        {maillErrorMessage && <small style={{ color: 'red' }}>*E-mail informado inválido.</small>}
        <br />
        <button
          onClick={event => handleCheckEmail(event)}
          className='btn btn-secondary'
        >
          Responder Pesquisa
        </button>
      </>
    ),
    secondSteep: _ => (
      <>
        <button style={{ border: 'none', padding: '0', marginBottom: '8px', backgroundColor: 'transparent' }} onClick={_ => setStep(1)}>
          <ArrowBack />
        </button>
        <h5>Agora sim vamos fazer algumas perguntas:</h5>
        <label>Em uma escala de 0 a 10, qual a chance de você indicar para um familiar ou amigo: <small>* Resposta obrigatória.</small></label>
        <div style={{ display: 'flex' }}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((opt) => (
            <div
              onClick={_ => setScoreNps(opt)}
              key={opt}
              className={`desable-nps-option ${scoreNps === opt && 'unable-nps-option'}`}
            >
              <span>{opt}</span>
            </div>
          ))}
        </div>
        <br />
        <label>Em poucas palavras, justifique sua resposta:</label>
        <textarea
          className='form-control'
          cols="30"
          rows="4"
          value={npsJustification}
          onChange={(event) => setNpsJustification(event.target.value)}
          maxLength="200"
        ></textarea>
        <br />
        {surveyData.ratingOptions ? (
          <>
            <label>Deixe também sua classificação sobre alguns itens:</label>
            {surveyData.ratingOptions.map((item) => (
              <div key={item.id} className='grig-container'>
                <h6>{item.displayName}</h6>
                <Rating
                  name={item.id}
                  value={ratings[item.id] || 0}
                  onChange={(event, newValue) => {
                    handleRating(item.id, newValue);
                  }}
                />
              </div>
            ))}
          </>
        ) : ''}
        <br />
        <button
          type='submit'
          className='btn btn-secondary'
          disabled={scoreNps === false}
        >
          Enviar Respostas
        </button>
      </>
    ),
    thirdSteep: _ => (
      <div className='thanks-section'>
        <div className='thanks-section'>
          <Logo
            height={150}
            asset='logo-responda-aqui-preta'
          />
          Obrigado!
          <br />
          <a className='site-link' href='http://www.responda-aqui.com'><i>Clique aqui</i></a>
          <span className='site-link-text'>para conhecer nosso site</span>
        </div>
      </div>
    )
  }

  return (
    <div className='app-container'>
      {surveyData ? (
        <div className='survey-container'>
          {step === 0 ? (
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
              <Logo
                height={150}
                asset='logo-responda-aqui-preta'
              />
            </div>
          ) : (
              <>
                {step !== 3 ? (
                  <>
                    <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>Pesquisa de Satisfação {surveyData.placeName}</h4>
                    <div className='questions-section'>
                      <div className='form-group'>

                        <form onSubmit={event => handleAnswer(event)}>
                          {step === 1 ? widgets.firstSteep() : widgets.secondSteep()}
                        </form>
                      </div>
                    </div>
                  </>
                ) : widgets.thirdSteep()}
              </>
            )
          }
        </div>
      ) : (
        <>
          Não existe pesquisa cadastrada para esse endereço
        </>
      )}
    </div>
  );
}
