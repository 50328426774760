import React from 'react';
import './styles.scss';

import Logo from '../../components/Logo';
import PricingTable from '../../components/PricingTable';
import { LINK_URL } from '../../constants/constants';

export default _ => (
  <>
    <nav className="navbar navbar-light bg-light static-top">
      <div className="container">
        <a className="navbar-brand" href="/#">
          <Logo
            height={60}
            asset='logo-responda-aqui-preta-nav'
          /> 
        </a>
        <a className="btn btn-link"  href={LINK_URL} target='_blank'>Login / Cadastro</a>
      </div>
    </nav>

    <br />

    <header className="masthead text-white text-center">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <img className="d-none d-sm-block" style={{ height: '60vh' }} src={require(`../../assets/banner-site.jpg`)} alt="banner"/>
            <img className="d-block d-sm-none" style={{ height: '35vh' }} src={require(`../../assets/small-banner.jpeg`)} alt="banner"/>
          </div>
        </div>
      </div>
    </header>

    <br />

    <section className="features-icons text-center">
      <div style={{ backgroundColor: '#fff' }} className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <div className="features-icons-icon d-flex">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                  <img style={{ width: '140px' }} src={require(`../../assets/whistle.png`)} alt=""/>
                </div>
              </div>
              <h3>Pesquisa rápida e objetiva</h3>
              <p className="lead mb-0">Você conhecerá a opinião dos seus clientes para tomar decisões mais assertivas no seu negócio.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
              <div className="features-icons-icon d-flex">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                  <img style={{ width: '120px' }} src={require(`../../assets/intuition.png`)} alt=""/>
                </div>
              </div>
              <h3>Plataforma intuitiva</h3>
              <p className="lead mb-0">Com apenas alguns cliques você cria a sua campanha e disponibiliza ao seus clientes.</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="features-icons-item mx-auto mb-0 mb-lg-3">
              <div className="features-icons-icon d-flex">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                  <img style={{ width: '140px' }} src={require(`../../assets/thermometer.png`)} alt=""/>
                </div>
              </div>
              <h3>Com o melhor custo x benefício do mercado</h3>
              <p className="lead mb-0">Confira nossos planos</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="showcase" style={{ padding: '50px' }}>
      <div className="container-fluid p-0">
        <div className="row no-gutters">

          <div className="col-lg-6 order-lg-2 text-white showcase-img">
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <img className='nps-image' src={require(`../../assets/nps-image.jpeg`)} alt=""/>
            </div>
          </div>
          <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            <h2>NPS - Net Promote Score</h2>
            <p className="lead mb-0">Net Promoter Score é uma métrica que tem como objetivo medir a satisfação e lealdade dos clientes com as empresas. Organizações de todos os portes e lugares do mundo utilizam o NPS por ser um método prático e eficaz durante as pesquisas periódicas realizadas com seus clientes.</p>
          </div>
        </div>
        <br/><br/>
        <div className="row no-gutters">
          <div className="col-lg-6 text-white showcase-img">
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <img style={{ width: '350px' }} src={require(`../../assets/scan-qrcode.jpeg`)} alt=""/>
            </div>
          </div>
          <div className="col-lg-6 my-auto showcase-text">
            <h2>A pesquisa</h2>
            <p className="lead mb-0">Cadastre-se no em nosso site, crie sua campanha e compartilhe com seus clientes o QR Code e/ou link da pesquisa.</p>
          </div>
        </div>
        <br/><br/>
        <div className="row no-gutters">
          <div className="col-lg-6 order-lg-2 text-white showcase-img">
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
              <img className="dashboard-image" src={require(`../../assets/dashboard.png`)} alt=""/>
            </div>
          </div>
          <div className="col-lg-6 order-lg-1 my-auto showcase-text">
            <h2>Acompanhe os resultados</h2>
            <p className="lead mb-0">Com nosso dashboard você terá acesso aos resultados das pesquisas instantaneamente.</p>
          </div>
        </div>
      </div>
    </section>

    <br/>

    <section className="testimonials text-center">
      <div className="container">
        <h2  className="mb-5" className="plans-message">Conheça nossos planos...</h2>
        <div className="row">
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <PricingTable />
          <a className="link-dash-message" href={LINK_URL} target='_blank'><i>Faça seu cadastro</i></a>
          <h6>E experimente nosso plano free</h6>
        </div>
        </div>
      </div>
    </section>

    <footer className="footer bg-light">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 h-100 text-center text-lg-left my-auto">
            <br/><br/>
            <p className="text-muted small mb-4 mb-lg-0">&copy; Responda Aqui 2020. Todos os direitos reservados.</p>
          </div>
        </div>
      </div>
    </footer>
  </>
);
