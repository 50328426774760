import React from 'react';
import './styles.scss';

export default _ => (
  <div className="pricing-table">

    <div className="pricing-item pricing-free">
      <div className="pricing-title">
        Experimental
      </div>
      <div className="pricing-value">
        Free
      </div>
      <ul className="pricing-features">
        <li><span className="keywords">50</span> primeiras respostas</li>
        <li><span className="keywords">2</span> logins por conta</li>
        <li>Relatório <span className="keywords">NPS</span></li>
      </ul>
    </div>

    <div className="pricing-item">
      <div className="pricing-title">
        Mensal
      </div>
      <div className="pricing-value">R$49.<span className="smallText">00</span>
        <span className="undertext">/Mês</span>
      </div>
      <ul className="pricing-features">
        <li><span className="keywords">150</span> respostas/mês</li>
        <li><span className="keywords">2</span> logins por conta</li>
        <li>Relatório <span className="keywords">NPS</span></li>
        <li><span className="keywords">Suporte via</span> email e whatsapp</li>
        <li><small>Assinatura Mesal</small></li>
      </ul>
    </div>

    <div className="pricing-item pricing-2">
      <div className="pricing-title">
        Semestral
      </div>
      <div className="pricing-value">R$249.<span className="smallText">00</span>
        <span className="undertext">/Semestre</span>
      </div>
      <ul className="pricing-features">
        <li><span className="keywords">300</span> respostas/mês</li>
        <li><span className="keywords">2</span> logins por conta</li>
        <li>Relatório <span className="keywords">NPS</span></li>
        <li><span className="keywords">Suporte via</span> email e whatsapp</li>
        <li><small>Parcelado em até 4x sem juros.</small></li>
      </ul>
    </div>

    <div className="pricing-item pricing-featured">
      <div className="pricing-title">
        Anual
      </div>
      <div className="pricing-value">R$449.<span className="smallText">00</span>
        <span className="undertext">/Ano</span>
      </div>
      <ul className="pricing-features">
        <li><span className="keywords">500</span> respostas/mês</li>
        <li><span className="keywords">2</span> logins por conta</li>
        <li>Relatório <span className="keywords">NPS</span></li>
        <li><span className="keywords">Suporte via</span> email e whatsapp</li>
        <li><small>Parcelado em até 4x sem juros.</small></li>
      </ul>
    </div>

  </div>
) 
